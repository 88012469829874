<template>
    <div style="min-height: 2000px">
        <div class="top-menu">
            <img v-if="haveDeck" class="back-button" @click="backToDeck" src="./back-to-deck-button.png" alt="back">
        </div>
        <CharacterCreatorComponent v-if="haveDeck === false" @deck="simulateDeck($event)"></CharacterCreatorComponent>
        <DeckPlayComponent v-else-if="haveDeck === true" :deck="drawPile"></DeckPlayComponent>
    </div>
</template>

<script>
import CharacterCreatorComponent from './CharacterCreatorComponent.vue';
import DeckPlayComponent from './DeckPlayComponent.vue';

export default {
    name: 'DeckSimComponent',
    components: {
        CharacterCreatorComponent,
        DeckPlayComponent
    },
    data() {
        return {
            drawPile: [],
            discardPile: [],
            haveDeck: false,
        };
    },
    methods: {
        simulateDeck(deck) {
            this.drawPile = deck;
            this.haveDeck = true;
        },
        backToDeck() {
            this.drawPile = [];
            this.haveDeck = false;
        }
    },  
};
</script>

<style scoped>
.active {
    text-decoration: underline;
}
.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.25s ease-in-out;
}
.fade-enter-from,
.fade-leave-to {
    opacity: 0;
}
.fade-enter-to,
.fade-leave-from {
    opacity: 1;
}

.top-menu {
    padding-top: 15px;
}


.back-button {
    width: auto;
    height: 17px;
    transition: transform 0.3s ease;
    color: #FFF3E4;
  }
  
.back-button:hover {
    transform: scale(1.1); /* Enlarge the link by 10% on hover */
    cursor: pointer;
    
}
</style>
