<template>
    <div>
        <nav class="menu-bar">
            <ul>
                <li><a href="#" @click.prevent="setCurrentComponent('Anachronist')" :class="{ 'active': currentComponent === 'Anachronist' }">The Anachronist</a></li>
                <li><a href="#" @click.prevent="setCurrentComponent('Flux')" :class="{ 'active': currentComponent === 'Flux' }">The Flux</a></li>
                <li><a href="#" @click.prevent="setCurrentComponent('Grafted')" :class="{ 'active': currentComponent === 'Grafted' }">The Grafted</a></li>
                <li><a href="#" @click.prevent="setCurrentComponent('Mended')" :class="{ 'active': currentComponent === 'Mended' }">The Mended</a></li>
                <li><a href="#" @click.prevent="setCurrentComponent('Pearl')" :class="{ 'active': currentComponent === 'Pearl' }">The Pearl</a></li>
                <li><a href="#" @click.prevent="setCurrentComponent('Sprouted')" :class="{ 'active': currentComponent === 'Sprouted' }">The Sprouted</a></li>
            </ul>
        </nav>
        <van-row justify="center">
            <van-col span="20">
                <div style="min-height: 1000px;">
                    <transition name="fade" mode="out-in">
                        <component :is="currentComponent" :key="currentComponent"></component>
                    </transition>
                </div>
            </van-col>
        </van-row>
        <div>
        </div>
    </div>
</template>

<script>
/* eslint-disable vue/no-unused-components */
import Anachronist from './historyComponents/AnachronistComponent.vue';
import Flux from './historyComponents/FluxComponent.vue';
import Grafted from './historyComponents/GraftedComponent.vue';
import Mended from './historyComponents/MendedComponent.vue';
import Pearl from './historyComponents/PearlComponent.vue';
import Sprouted from './historyComponents/SproutedComponent.vue';

export default {
    name: 'PathFiles',
    data() {
        return {
            currentComponent: 'Anachronist',
        };
    }, 
    methods: {
        setCurrentComponent(component) {
            this.currentComponent = component;
        }
    },
    components: {
        Anachronist,
        Flux,
        Grafted,
        Mended,
        Pearl,
        Sprouted,
    },
};
</script>

<style scoped>
.active {
    text-decoration: underline;
}
.fade-enter-active, .fade-leave-active {
    transition: opacity .25s ease-in-out;
}
.fade-enter-from, .fade-leave-to {
    opacity: 0;
}
.fade-enter-to, .fade-leave-from {
    opacity: 1;
}

</style>
    

