<template>
    <div>
        <van-row justify="center" gutter="20">
            <van-col xs="24" sm="12" md="8" lg="8">
                <div class="main-content">
                    <p>Grafting human beings, that is taking characteristics from a plant or animal and applying it to a living human, is forbidden in almost every part of The Horn.  Despite that, it does happen, sometimes out of hubris, and sometimes out of necessity. </p>
                </div>
            </van-col>
            <van-col xs="24" sm="12" md="8" lg="8">
                <div class="main-content">
                    <div>
                        <h2>Features</h2>
                        <div class="buffered-content">
                            <ul class="features">
                                <li>You carry some graft from another plant or animal</li>
                                <li>A small creature is drawn to your graft.  It follows you everywhere, and you’ve grown quite attached to it</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </van-col>
        </van-row>
    </div>
</template>

<script>
export default {
    name: 'GraftedComponent',
    data() {
        return {
            // Your data properties here
        };
    }
};
</script>

