<template>
    <div>
        <van-row justify="center" gutter="20">
            <van-col xs="24" sm="12" md="8" lg="8">
                <div class="main-content">
                    <p>Whatever force allows the weave to transform the natural world has rooted itself deep within you.  Your physical features are constantly shifting.  Your mood plays across your skin, turning blue and semi translucent, or red and pulsing with a gentle glow. </p>
                </div>
            </van-col>
            <van-col xs="24" sm="12" md="8" lg="8">
                <div class="main-content">
                    <div>
                        <h2>Features</h2>
                        <div class="buffered-content">
                            <ul class="features">
                                <li>You may change the colour and luminance of your skin at will</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </van-col>
        </van-row>
    </div>
</template>

<script>
export default {
    name: 'FluxComponent',
    data() {
        return {
            // Your data properties here
        };
    }
};
</script>

