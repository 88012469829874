<template>
    <div>
        <div v-hover="showTooltip" class="stepper-container element">
            <h1 class="stepper-title" >Threads</h1>
            <div class="stepper">
                <img class="bulge" :class="{ disabled: selectedThreads.includes('love') }" src="./loveThreadLogo.png" @click="toggleThread('love')" alt="Decrement">
                <img class="bulge" :class="{ disabled: selectedThreads.includes('look') }" src="./lookThreadLogo.png" @click="toggleThread('look')" alt="Decrement">
                <img class="bulge" :class="{ disabled: selectedThreads.includes('compare') }" src="./compareThreadLogo.png" @click="toggleThread('compare')" alt="Decrement">
                <img class="bulge" :class="{ disabled: selectedThreads.includes('season') }" src="./seasonThreadLogo.png" @click="toggleThread('season')" alt="Decrement">
                <img class="bulge" :class="{ disabled: selectedThreads.includes('lk') }" src="./lkThreadLogo.png" @click="toggleThread('lk')" alt="Decrement">
            </div>
            <div class="footer">
                <img src="./dividerA.png" alt="-------------">
            </div>
        </div>
    </div>  
</template>

<script>
import { ref } from 'vue';

export default {
    name: 'ThreadComponent',
    props: {
        initValue: {
            type: Number,
            required: true,
        },
        min: {
            type: Number,
            default: 0,
        },
        max: {
            type: Number,
            default: Infinity,
        },
    },
    setup() {
        const show = ref(false);
        return { show };
    },
    data() {
        return {
            selectedThreads: [],
            showToolTip: false,
        }
    },
    directives: {
        hover: {
            bind(el, binding) {
                el.onmouseover = () => (binding.value(true));
                el.onmouseout = () => (binding.value(false));
            },
        },
    },
    methods: {
        toggleThread(thread) {
            if (this.selectedThreads.includes(thread)) {
                this.selectedThreads = this.selectedThreads.filter((t) => t !== thread);
                this.$emit('remove', thread);
            } else {
                this.selectedThreads.push(thread);
                this.$emit('add', thread);
            }
        },
  },
};
</script>
<style scoped>

.stepper-container {
  max-width: 300px;
}
.stepper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: -10px;
}

.stepper .stepper-title {
    order: 1
}

.stepper .stepper-controls {
    order: 2
}

.stepper-controls {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: -10px;
}

.stepper img {
  height: 50px;
  width:auto;
  padding: 5px;
  margin-top: 5px;
  cursor: pointer;
}

.stepper-controls h1 {
  font-size: 3.5em;
  margin: 0;
}

.header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.header .stepper-title {
  margin-bottom: 0px;
}

.header img {
  width: 100px;
  height:auto;
}

.footer {
  display: flex;
  align-items: center;
  justify-content: right;
}

.footer img {
  width: 300px;
  height:auto;
  margin-top: 0px;
}

.disabled {
    filter: brightness(50%);
}

.element {
  position: relative;
  display: inline-block;
}

.tooltip {
  position: absolute;
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
  background-color: #333;
  color: #fff;
  padding: 10px;
  border-radius: 5px;
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s, opacity 0.5s linear;
}

.element:hover .tooltip {
  visibility: visible;
  opacity: 1;
}
</style>
