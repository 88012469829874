<template>
    <div>
        <van-row justify="center" gutter="20">
            <van-col xs="24" sm="12" md="8" lg="8">
                <div class="main-content">
                    <h2>Harmony and Grace</h2>
                    <p>The weave brings rapid and unpredictable change.  Landmarks can shift out of focus or disappear entirely.  The Atlas is someone who’s attunement and preternatural sense of direction allows them navigate an ever changing landscape.  A guide, a tracker, and a map maker. </p>
                    <div class="break"></div>
                    <div class="image-center">
                        <a :href="atlasPdf" class="download-button">Download Atlas Playbook</a>
                    </div>
                </div>
            </van-col>
            <van-col xs="24" sm="12" md="8" lg="8">
                <div class="main-content">
                    <div>
                        <h2>Moves</h2>
                        <div class="buffered-content">
                            <ul class="features">
                                <li><strong>Find your way:</strong> When you would like the weave to guide you to a destination, test with <strong>Harmony</strong></li>
                                <li><strong>Track:</strong> When you want to understand the path something has taken through the world and follow it, test with <strong>Grace</strong></li>
                            </ul>
                        </div>
                        <h2>Features</h2>
                        <div class="buffered-content">
                            <ul class="features">
                                <li>You start the game with a relic that aids in navigation. This relic can only be used when taking the Find your way and Track moves</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </van-col>
        </van-row>
    </div>
</template>

<script>
import downloadButton from '@logos/downloadButtonLogo.png'
import atlasPdf from '@pathPdfs/Atlas.pdf'

export default {
    name: 'AtlasComponent',
    data() {
        return {
            downloadButton: downloadButton,
            atlasPdf: atlasPdf
        };
    }
};
</script>
