<template>
    <div>
        <van-row justify="center" gutter="20">
            <van-col xs="24" sm="12" md="8" lg="8">
                <div class="main-content">
                    <p>Occasionally Kel will ignore someone, leaving them to seek another afterlife.  Most will simply die, but some tiny number are found by Kitsu in the fleeting moments between life and death.  Kitsu is the mender.  They will examine something and do their best to rebuild what has been damaged, replacing limbs and closing splayed rib cages.  Folks who have been mended return as something more than themselves, and are often shunned and distrusted by their community, with some suggesting that Kel’s disinterest is a kind of judgement.  Mended will often have delicate lines of liquid gold filigree spreading over large sections of their body tying together sections of mismatched skin, some of which might not look like flesh at all.  They cannot be turned into bodum.  </p>
                </div>
            </van-col>
            <van-col xs="24" sm="12" md="8" lg="8">
                <div class="main-content">
                    <div>
                        <h2>Features</h2>
                        <div class="buffered-content">
                            <ul class="features">
                                <li>One injury is removed from your deck each day</li>
                                <li>You do not age </li>
                                <li class="sub-content">Mended are effectively immortal, but they are not immune to harm.  Any kind of catastrophic injury that destroys their body can still kill them. </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </van-col>
        </van-row>
    </div>
</template>

<script>
export default {
    name: 'MendedComponent',
    data() {
        return {
            // Your data properties here
        };
    }
};
</script>

