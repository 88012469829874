<template>
    <div>
        <nav class="menu-bar">
            <ul>
                <li><a href="#testing">Testing</a></li>
                <li><a href="#basic-moves">Basic Moves</a></li>
                <li><a href="#special-moves">Special Moves</a></li>
                <li><a href="#tender-moves">Tender Moves</a></li>
            </ul>
            <ul style="margin-top: -30px;">
                <li><a href="#complication-prompts">Complications</a></li>
                <li><a href="#assist">Assisting</a></li>
                <li><a href="https://world.wick-and-wander.com">Dev Docs</a></li>
            </ul>
        </nav>
        <div class="main-content">
            <van-row justify="center">
                <van-col class="van-col" span="24">
                    <h2>The Game</h2>
                    <p>
                        Wick and Wander is a card driven TTRPG that uses a custom deck of attribute cards to capture a characters nature.  It is inspired by PbtA and so uses moves to describe what characters are able to do while exploring the world. Each character has access to a set of common moves, special moves, community moves and a small set of moves associated with their personal Path and History.
                    </p>
                    <p>
                        As players explore the world, they may want to attempt particular actions, or be forced to by circumstance.  If the actions they want to attempt are non-trivial then players and the Lore Keeper (LK) work together to find a move that captures the players intent.  The player makes a test.
                    </p>
                </van-col>
            </van-row>
            <div class="break"></div>
            <van-row id="testing" justify="center">
                <van-col span="22">
                    <h2>Testing</h2>
                    <ul class="features">
                        <li>Declare the move you are using and the attribute you are testing for. If a move has multiple options such as Lore or Empathy, you must choose one before the test begins, and only that one is considered for the test.</li>
                        <li>Draw cards from your character deck, moving them to your discard pile as you draw them. Continue until one of the following things happens. Tests cannot be stopped prematurely.
                            <ul class="features">
                                <li class="sub-content">You draw the attribute you are looking for - Count the number of cards flipped and follow the instructions on the Degrees of Complication table</li>
                                <li li class="sub-content">You draw a Thread - Threads are considered wild during a test. This is equivalent to finding the attribute you are testing for but must include a rationale for how the thread (typically a person) factors into the resulting narrative. A thread always constitutes a Minor Complication no matter how many cards have been drawn. Bringing the person into the narrative doesn’t require that you reference the source question (i.e., it doesn’t have to explicitly be your love for the person that drives the narrative, just the person themselves).
                                </li>
                                <li li class="sub-content">You draw a stitch card - stitch cards behave exactly like threads except instead of referencing a person, they reference a season. Include some memory from a past season in the narrative. The memories do not need to be perfectly accurate. Memories rarely are.</li>
                                <li li class="sub-content">You draw an Injury card - The test ends with a Major Complication. The player chooses an Injury from their character sheet, and the Lore Keeper includes it in the narrative.</li>
                                <li li class="sub-content">You draw a relic card. Place it back in your tableau and continue the test. Relics don't count towards your complication total.</li>
                                <li li class="sub-content">You exhaust your deck - The test ends with a Major Complication as you are forced to take a breath. The Lore Keeper should try and work your exhaustion into the narrative. After you have resolved any complications, shuffle your discard pile back into your draw deck.</li>
                            </ul>
                        </li>
                    </ul>
                </van-col>
            </van-row>
            <div class="break"></div>
            <van-row id="basic-moves" justify="center">
                <van-col span="22">
                    <h2>Basic Moves</h2>
                    <ul class="features">
                        <li><strong>Reflect on what you’ve learned:</strong> When you want to remember something, test with Lore.</li>
                        <li><strong>Find what you are looking for:</strong> When you want to know something specific about the immediate area, test with Lore or Grace.</li>
                        <li><strong>Attune to the weave:</strong> When you want to listen for Daemons or creatures nearby and understand something specific about them, test with Harmony.</li>
                        <li><strong>Push yourself to your limits:</strong> When you push your body or mind to its limit, test with Power.</li>
                        <li><strong>Listen and be heard:</strong> When you want to understand someone's intentions or convince them of something, test with Empathy.</li>
                        <li><strong>Step Lightly:</strong> When you want to move carefully or avoid detection, test with Grace.</li>
                        <li><strong>Survive:</strong> When you are the target of violence and want to avoid injury, test with Grace or Power.</li>
                        <li><strong>Improvise:</strong> When you are trying to solve a problem in an unconventional way, you may improvise. Describe what you are attempting to do and test with the appropriate attribute. This move always comes with a complication.</li>
                    </ul>
                </van-col>
            </van-row>
            <div class="break"></div>
            <van-row id="special-moves" justify="center">
                <van-col class="van-col" span="22">
                    <h2>Special Moves</h2>
                    <ul class="features">
                        <li><strong>Provide Care:</strong> Once per day when you make camp and wish to help another party member remove an injury, explain how you care for them, then have them remove a single injury of their choice from their discard pile or draw deck. If they searched through their draw deck, have them shuffle it now.</li>
                        <li><strong>Use Relic:</strong> When you would like to use one of your relics to shift the outcome of a test in your favor, move the relic card from your character tableau to your discard pile. The complication severity is decreased. Major Complications become Minor Complications, and Minor Complications become Favored Outcomes.</li>
                        <li><strong>Return to the weave:</strong> When all available injuries have been added to your deck you fall unconscious, check if it is your time to return to the weave. Shuffle your character deck. Draw until you encounter a thread or an injury.
                        </li>
                        <li class="sub-content">If you draw a thread, explain how that person pulled you back from the brink, remove 1 injury from your deck, you are now conscious.</li>
                        <li class="sub-content">If you draw an injury card, your character has died, describe how they pass, and which form they want to take in death. Kel will visit them once their stone fragment is removed.</li>
                        <li><strong>Grow:</strong> When you encounter a bodum, you may harvest some piece of it, and after consuming it, add a thread to your character deck. The Lore Keeper should help explain the memory you’ve found.</li>
                        <li><strong>Reflect:</strong> When you have arrived home after a long journey and the season is drawing to a close, you and your companions should take time to reflect on the journey you’ve been on. Go through your threads and talk with the group about how those relationships have changed and how you have been changed by them then set the threads aside. This will help prepare you for the next season of the game.</li>
                    </ul>
                </van-col>
            </van-row>
            <div class="break"></div>
            <van-row id="tender-moves" justify="center">
                <van-col class="van-col" span="22">
                    <h2>Tender Moves</h2>
                    <p>
                        Tender moves are unique in that they do not require a test.  They simply describe something true about your character and a way for your character to interact with other people in the world.  
                    </p>
                    <ul class="features">
                        <li>You like getting up early before the rest of the community, making a tea and walking through the gardens on the outskirts of town. When you want to help someone find peace in solitude, you may.</li>
                        <li>You have a laugh that bubbles out of you at the slightest provocation. When you want to help someone find joy in a painful situation, you may.</li>
                        <li>Someone you love has hair that smells like lavender and you think of it often. When you want to help someone remember a loved one, you may.</li>
                        <li>You have a friend who makes you laugh so hard that you cry. When you want to remind someone that love redoubles in the giving, you may.</li>
                        <li>You grew up next to a pond that came alive in the rain, so the smell of a storm makes you think of home. When you want to help someone see a hardship in a positive light, you may.</li>
                        <li>The feeling of sunshine on your skin inspires you to bring warmth and love into the world. When you want to reassure someone that even the heaviest rain eventually relents, you may.</li>
                        <li>You have always shown love through food, and there is always a place at your table. When you want to show someone that a meal with friends is always a feast, you may.</li>
                        <li>You remember laughing and dancing with your family to the music filtering in from the town square. When you want to help someone find joy in the simplest of moments, you may.</li>
                        <li>Your grandparents house always smelled of freshly baked bread. When you want to warm someone with thoughts of home, you may.</li>
                        <li>You have a collection of seashells from every beach you’ve visited, reminding you of the ocean’s vastness. When you want to help someone find perspective in life’s journey, you may.</li>
                        <li>You have a small seashell from a family pilgrimage west to the ocean when you were a child. When you want to help someone see the simple pleasure of a long journey, you may.</li>
                        <li>You would often go on multi day foraging trips with your aunt as a child, camping overnight in the fields of the borderlands, and staring up at the star filled sky. When you want to show someone that solitude can be beautiful, you may.</li>
                        <li>You come from a family of artists and craftspeople, knitting, painting, sculpting, and singing filling every empty moment. When you want to show someone that making art soothes the soul, you may.</li>
                        <li>You remember your mother mulling apple cider during the late autumn months and serving it out your kitchen window as you played with the animals. When you want to help someone see beauty in the inevitable changing of the seasons, you may.</li>
                    </ul>
                </van-col>
            </van-row>
            <div class="break"></div>
            <van-row id="complication-prompts" justify="center">
                <van-col class="van-col" span="18">
                    <h2>Degrees of Complication</h2>
                    <ul class="simple-features">
                        <li><strong>(1) Favored Outcome:</strong> Things go as well as you can imagine them going.  You get what you were after and then some.  The Lore Keeper describes how the narrative progresses and bends in your favor. </li>
                        <li><strong>(2-3) Minor Complication:</strong> You get some or all of what you were after, but things get complicated. The player chooses a complication prompt and the Lore Keeper elaborates.</li>
                        <li><strong>(4+) Major Complication:</strong> You may get some of what you were after, but things likely get much worse, escalating with the number of cards drawn.  The Lore Keeper controls the narrative entirely.  </li>
                    </ul>
                    <h2>Complication Prompts</h2>
                    <ul class="features">
                        <li class="no-bullets"><strong>Universal</strong></li>
                        <li class="sub-content">You take an injury</li>
                        <li class="sub-content">You imperil someone else</li>
                        <li class="no-bullets"><strong>Lore</strong></li>
                        <li class="sub-content">You learn something that scares you</li>
                        <li class="sub-content">Something you hold dear is called into doubt</li>
                        <li class="sub-content">You misremember a key detail</li>
                        <li class="no-bullets"><strong>Empathy</strong></li>
                        <li class="sub-content">You anger something</li>
                        <li class="sub-content">You are overwhelmed by your feelings</li>
                        <li class="sub-content">You must keep someone’s secret</li>
                        <li class="no-bullets"> <strong>Harmony</strong></li>
                        <li class="sub-content">The world lashes out at you</li>
                        <li class="sub-content">Something powerful catches your scent</li>
                        <li class="sub-content">You destabilize the area</li>
                        <li class="no-bullets"><strong>Power</strong></li>
                        <li class="sub-content">You break something precious</li>
                        <li class="sub-content">You are completely exhausted</li>
                        <li class="sub-content">Something reminds you that you are weak</li>
                        <li class="no-bullets"><strong>Grace</strong></li>
                        <li class="sub-content">You break something precious</li>
                        <li class="sub-content">You stumble</li>
                        <li class="sub-content">You make a huge miscalculation</li>
                    </ul>
                </van-col>
            </van-row>
            <div class="break"></div>
            <van-row id="assist" justify="center">
                <van-col class="van-col" span="24">
                    <h2>Assisting Others</h2>
                    <p>
                        At any point during a test, another player may offer to assist.  They take a test that is narratively appropriate in support of your efforts.  They are subject to any complications from their own test, but their assistance lessens your complications.  If you would have had major complications, they become minor, and if you would have had minor complications they become a favored result.  The complications directed at the player who is assisting are resolved as normal.  
                    </p>
                </van-col>
            </van-row>
        </div> 
    </div>
</template>

<script>
export default {
    name: 'CoreRules',
}
</script>

