<template>
    <div>
        <nav class="menu-bar">
            <ul>
                <li><a href="#" @click.prevent="setCurrentComponent('Atlas')" :class="{ 'active': currentComponent === 'Atlas' }">The Atlas</a></li>
                <li><a href="#" @click.prevent="setCurrentComponent('Catalyst')" :class="{ 'active': currentComponent === 'Catalyst' }">The Catalyst</a></li>
                <li><a href="#" @click.prevent="setCurrentComponent('Conduit')" :class="{ 'active': currentComponent === 'Conduit' }">The Conduit</a></li>
                <li><a href="#" @click.prevent="setCurrentComponent('Scarred')" :class="{ 'active': currentComponent === 'Scarred' }">The Scarred</a></li>
                <li><a href="#" @click.prevent="setCurrentComponent('Poet')" :class="{ 'active': currentComponent === 'Poet' }">The Poet</a></li>
                <li><a href="#" @click.prevent="setCurrentComponent('Naturalist')" :class="{ 'active': currentComponent === 'Naturalist' }">The Naturalist</a></li>
            </ul>
        </nav>
        <van-row justify="center">
            <van-col span="20">
                <div style="min-height: 1000px;">
                    <transition name="fade" mode="out-in">
                        <component :is="currentComponent" :key="currentComponent"></component>
                    </transition>
                </div>
            </van-col>
        </van-row>
        <div>
        </div>
    </div>
</template>

<script>
/* eslint-disable vue/no-unused-components */
import Atlas from './pathComponents/AtlasComponent.vue';
import Catalyst from './pathComponents/CatalystComponent.vue';
import Conduit from './pathComponents/ConduitComponent.vue';
import Naturalist from './pathComponents/NaturalistComponent.vue';
import Poet from './pathComponents/PoetComponent.vue';
import Scarred from './pathComponents/ScarredComponent.vue';

export default {
    components: {
        Atlas,
        Catalyst, 
        Conduit, 
        Naturalist, 
        Poet, 
        Scarred,
    },
    name: 'PathFiles',
    data() {
        return {
            currentComponent: 'Atlas',
        };
    }, 
    methods: {
        setCurrentComponent(component) {
            this.currentComponent = component;
        }
    }
};
</script>

<style scoped>
.active {
    text-decoration: underline;
}
.fade-enter-active, .fade-leave-active {
    transition: opacity .25s ease-in-out;
}
.fade-enter-from, .fade-leave-to {
    opacity: 0;
}
.fade-enter-to, .fade-leave-from {
    opacity: 1;
}

</style>

