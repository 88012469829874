<template>
    <div>
        <van-row justify="center" gutter="20">
            <van-col  xs="24" sm="12" md="8" lg="8">
                <div class="main-content">
                    <h2>Harmony and Empathy</h2>
                    <p>
                        The conduit is someone born with the rare ability to create or modify Daemons.  Complex spritual automata, Daemons are created with a single vocation, a goal they will pursue doggedly for as long as they exist.  Nobody truly understands them, but the Conduit comes close.
                    </p>
                    <div class="break"></div>
                    <div class="image-center">
                        <a :href="conduitPdf" class="download-button">Download Conduit Playbook</a>
                    </div>
                </div>
            </van-col>
            <van-col  xs="24" sm="12" md="8" lg="8">
                <div class="main-content">
                    <h2>Moves</h2>
                    <div class="buffered-content">
                        <ul class="features">
                            <li><strong>Manifest:</strong> When you wish to create a simple Daemon, explain what it looks like and its vocation and test with <strong>Harmony</strong></li>
                            <li><strong>Negotiate:</strong> When you wish to convince a Daemon to subtly change its vocation, test with <strong>Empathy</strong></li>
                            <li><strong>Banish:</strong> When you wish to Dismiss a Daemon entirely, test with <strong>Harmony</strong>. This dismissal is often temporary</li>
                        </ul>
                    </div>
                </div>
            </van-col>
        </van-row>
    </div>
</template>

<script>
import conduitPdf from '@pathPdfs/Conduit.pdf'
export default {
    name: 'ConduitComponent',
    data() {
        return {
            conduitPdf: conduitPdf
            // Your data properties here
        };
    }
};
</script>

