<template>
    <div>
        <van-row justify="center" gutter="20">
            <van-col xs="24" sm="12" md="8" lg="8">
                <div class="main-content">
                    <p>As a child, your parents would tell you stories of the world before the awakening.  Wondrous and terrifying warnings about destructive communities living without the weave.  Yet buried in the horrors were glimmers of brilliance.</p>

                    <p>After your parents passed, you found a small package in the back of their closet.  A small relic  wrapped in wax paper and hidden away.  You don’t know what it might have been for, but it thrums with power.</p>

                    <p>You would learn as an adult that your parents were at odds with the community. Their fixation on the past was viewed with suspicion. So you inherited two things from your parents.</p>
                </div>
            </van-col>
            <van-col xs="24" sm="12" md="8" lg="8">
                <div class="main-content">
                    <div>
                        <h2>Features</h2>
                        <div class="buffered-content">
                            <ul class="features">
                                <li>You inherited a family heirloom. It’s a small relic from before the awakening that you keep with you at all times </li>
                                <li class="sub-content">Examples of Relics</li>
                                <li class="sub-content">A small figurine hanging from a leather cord. The figurine is made of dark purple crystal that is harder than any metal you’ve ever encountered. You can’t imagine how it was made </li>
                                <li class="sub-content">A Smooth metal rectangle with deep geometric grooves etched into every face. Sliding a finger from one end to the other will cause the entire network of grooves to glow a slight greenish colour</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </van-col>
        </van-row>
    </div>
</template>

<script>
export default {
    name: 'AnachronistComponent',
    data() {
        return {
            // Your data properties here
        };
    }
};
</script>

