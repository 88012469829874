<template>
    <div>
        <div class="stepper-container">
            <div class="stepper">
                <h1 class="stepper-title">{{title}}</h1>
                <div class="stepper-controls">
                    <img class="bulge" src="./downArrow.png" @click="decrement" alt="Decrement">
                    <h1>{{ localValue }}</h1>
                    <img class="bulge" src="./upArrow.png" @click="increment" alt="Increment">
                </div>
            </div>
            <div class="footer">
                <img src="./dividerA.png" alt="-------------">
            </div>
        </div>
    </div>      
</template>

<script>
export default {
    name: 'StepperComponent',
    props: {
        initValue: {
            type: Number,
            required: true,
        },
        min: {
            type: Number,
            default: 0,
        },
        max: {
            type: Number,
            default: Infinity,
        },
        label: {
            type: String,
            default: '',
        },
        title: {
            type: String,
            default: '',
        },
    },
    data() {
        return {
            localValue: this.initValue,
        }
    },
    methods: {
    increment() {
      if (this.localValue < this.max) {
        this.localValue++;
        this.$emit('increment', this.localValue);
      }
    },
    decrement() {
      if (this.localValue > this.min) {
        this.localValue--;
        this.$emit('decrement', this.localValue);
      }
    },
  },
};
</script>
<style scoped>

.stepper-container {
  max-width: 300px;
}
.stepper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: -10px;
}

.stepper .stepper-title {
    order: 1
}

.stepper .stepper-controls {
    order: 2
}

.stepper-controls {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: -10px;
}

.stepper-controls img {
  width: 50px;
  height:auto;
  padding: 5px;
  margin-top: 5px;
  cursor: pointer;
}

.stepper-controls h1 {
  font-size: 3.5em;
  margin: 0;
}

.header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.header .stepper-title {
  margin-bottom: 0px;
}

.header img {
  width: 100px;
  height:auto;
}

.footer {
  display: flex;
  align-items: center;
  justify-content: right;
}

.footer img {
  width: 300px;
  height:auto;
  margin-top: -15px;
}
</style>
