import { createApp } from 'vue'
import App from './App.vue'
import router from './router.js' // Import the router file
import './assets/main.css'
import 'vant/lib/index.css';
import { Col, Row, BackTop} from 'vant'
import { Stepper } from 'vant';
import VueTippy from 'vue-tippy'

const app = createApp(App)
app.use(VueTippy)
app.use(router) // Use the router plugin
app.use(Col)
app.use(Row)
app.use(BackTop)
app.use(Stepper);
app.mount('#app')
