<template>
    <div>
        <van-row justify="center" gutter="20">
            <van-col xs="24" sm="12" md="8" lg="8">
                <div class="main-content">
                    <p>You were born with white pearlescent eyes that glimmer and change colour in the sunlight like a seashell fragment.  You do not see like other people do, instead you see the currents and eddies of the weave itself.  You see explosions of light and colour.  Rushing lines of energy coiling around everything.  Singing stones limit this ability, and those who wear stone fragments are effectively invisible, though you see the weave flowing around them.  As though you could see the river, but not the rock.  You may have needed assistance navigating your community when you were younger since it likely exists entirely within a singing stone’s calming zone. 
                    </p>
                </div>
            </van-col>
            <van-col xs="24" sm="12" md="8" lg="8">
                <div class="main-content">
                    <div>
                        <h2>Features</h2>
                        <div class="buffered-content">
                            <ul class="features">
                                <li>When you observe Daemons, you can also see a current indicating where they came from and where they are going. </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </van-col>
        </van-row>
    </div>
</template>

<script>
export default {
    name: 'PearlComponent',
    data() {
        return {
            // Your data properties here
        };
    }
};
</script>

