<template>
    <div>
        <van-row justify="center" gutter="20">
            <van-col xs="24" sm="12" md="8" lg="8">
                <div class="main-content">
                    <p>You were very near death at some point in the past.  In that moment, Kel the Deamon of Death, touched you, but you asked to stay.  What was left is a hand print of rough skin that sprouts a single bodum fruit. Small berry branches, or delicate mushrooms are common.</p>
                </div>
            </van-col>
            <van-col xs="24" sm="12" md="8" lg="8">
                <div class="main-content">
                    <div>
                        <h2>Features</h2>
                        <div class="buffered-content">
                            <ul class="features">
                                <li>You may give an additional thread to another player around the table.  The nature of that thread is up to the two of you</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </van-col>
        </van-row>
    </div>
</template>

<script>
export default {
    name: 'SproutedComponent',
    data() {
        return {
            // Your data properties here
        };
    }
};
</script>

