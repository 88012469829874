<template>
  <NavigationBar />
  <van-row justify="center">
    <van-col span="14">
      <div class="van-hairline--top"></div>
    </van-col>
  </van-row>
    <transition name="fade" mode="out-in">
      <router-view />
    </transition>
    <img @click="backToTop" :src="backToTopLogo" class="fixed-image" alt="back to the top"/>
</template>

<script>
import NavigationBar from './layouts/NavigationBar.vue'
import backToTopLogo from '@logos/wnwBackToTopLogo.png'

export default {
  name: 'App',
  components: {
    NavigationBar,
    }, data() {
      return {
          backToTopLogo
      }
    },
    methods: {
      backToTop() {
        window.scrollTo({
          top: 0,
          behavior: 'smooth'
        })
      }
    }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

.van-hairline--top {
  margin-bottom: 5px;
  margin-top: 5px;
}

.fade-enter-active, .fade-leave-active {
    transition: opacity .5s ease-in-out;
}
.fade-enter-from, .fade-leave-to {
    opacity: 0;
}
.fade-enter-to, .fade-leave-from {
    opacity: 1;
}

.fixed-image {
  position: fixed;
  right: 50px;
  bottom: 50px;
  max-width: 60px;
  max-height: auto;
  transition: transform 0.3s ease;
}

.fixed-image:hover {
    transform: scale(1.1); /* Enlarge the link by 10% on hover */
    cursor: pointer;
    
}

@media (max-width: 845px) {
  .fixed-image {
    display: none;
  }
}
</style>
