<template>
    <div>
        <van-row justify="center" gutter="20">
            <van-col xs="24" sm="12" md="8" lg="8">
                <div class="main-content">
                    <h2>Empathy</h2>
                    <p>The history of our world can be found in books, songs, and stories, but the deep truth of things are whispered on the wind, or written into the bark of trees.  Poets know much about the world as it is and will spend their whole lives trying to put to paper those things for which we have no words. </p>
                    <div class="break"></div>
                    <div class="image-center">
                        <a :href="poetPdf" class="download-button">Download Poet Playbook</a>
                    </div>
                </div>
            </van-col>
            <van-col xs="24" sm="12" md="8" lg="8">
                <div class="main-content">
                    <div>
                        <h2>Moves</h2>
                        <div class="buffered-content">
                            <ul class="features">
                                <li>
                                    <strong>Whisper:</strong> when you want to share a truth over a long distance, whisper it into the weave and test with <strong>Empathy</strong>
                                </li>
                                
                            </ul>
                        </div>
                        <h2>Features</h2>
                        <div class="buffered-content">
                            <ul class="features">
                                <li>
                                    You may choose two additional Tender Moves
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </van-col>
        </van-row>
    </div>
</template>

<script>
import poetPdf from '@pathPdfs/Poet.pdf'
export default {
    name: 'PoetComponent',
    data() {
        return {
            poetPdf: poetPdf
            // Your data properties here
        };
    }
};
</script>

