import { createRouter, createWebHistory } from 'vue-router';
import CoreRules from '@/components/CoreRules.vue';
import Home from '@/components/HomePage.vue';
import PathFiles from '@/components/PathFiles.vue';
import HistoryOptions from '@/components/HistoryOptions.vue';
import DeckSimComponent from '@/components/DeckSimulator/DeckSimComponent.vue';

const routes = [
    {
        path: '/',
        name: 'HomePage',
        component: Home
    },
    {
        path: '/coreRules',
        name: 'CoreRules',
        component: CoreRules
    }, 
    {
        path: '/pathFiles',
        name: 'PathFiles',
        component: PathFiles
    },
    {
        path: '/historyOptions',
        name: 'HistoryOptions',
        component: HistoryOptions
    },
    {
        path: '/deckSim',
        name: 'DeckSim',
        component: DeckSimComponent
    },                              
  // Add more routes here as needed
];

const router = createRouter({
  history: createWebHistory(),
  routes
});

export default router;
