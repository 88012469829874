<template>
    <div class="main-content">

        <van-row justify="center">
            <van-col class="van-col" span="24">
                <p>
                    The world of wick and wander is big, complex, and currently unedited.  If any part of the central mechanics or vibe resonate with you, please follow the discord link above and join us.  I will gladly share the in progress world book and talk to you endlessly about the setting.

                    and here are two podcast episodes about the game:
                </p>
                
                <div class="break"></div>

                <iframe height="200px" width="100%" frameborder="no" scrolling="no" seamless src="https://player.simplecast.com/76cc7142-0cd0-4f83-af51-9e5101ad2d0a?dark=true"></iframe>

                <div class="break"></div>

                <h3>~36 min mark for the impatient</h3>
                <iframe height="200px" width="100%" frameborder="no" scrolling="no" seamless src="https://player.simplecast.com/db7b0d98-cec9-4833-9b02-8a2849b7b9b7?dark=true"></iframe>
            </van-col>
        </van-row>
    </div>      
</template>

<script>
import flora from '../../data/flora.json'
export default {
    name: 'WorldComponent',
    data() {
        return {
            flora: flora
        };
    }
}
</script>
<style>
.real-a {
    display: inline-block;
    color: #FFF3E4;
    text-align: center;
    padding: 14px 16px;
    text-decoration: none;
    transition: transform 0.3s ease;
}

.real-a:hover {
    transform: scale(1.1); /* Enlarge the link by 10% on hover */
}
</style>

