<template>
    <div>
        <nav class="menu-bar">
            <ul>
                <li><a href="#" @click.prevent="setCurrentComponent('Game')" :class="{ 'active': currentComponent === 'Game' }">The Game</a></li>
                <li><a href="#" @click.prevent="setCurrentComponent('World')" :class="{ 'active': currentComponent === 'World' }">The World</a></li>
            </ul>
        </nav>
        <van-row justify="center">
            <van-col span="20">
                <div style="min-height: 1000px;">
                    <transition name="fade" mode="out-in">
                        <component :is="currentComponent" :key="currentComponent"></component>
                    </transition>
                </div>
            </van-col>
        </van-row>
        <div>
        </div>
    </div>
</template>

<script>
/* eslint-disable vue/no-unused-components */
import Game from './homeComponents/GameComponent.vue';
import World from './homeComponents/WorldComponent.vue';

export default {
    components: {
        Game, 
        World,
    },
    name: 'HomePage',
    data() {
        return {
            currentComponent: 'Game',
        };
    }, 
    methods: {
        setCurrentComponent(component) {
            this.currentComponent = component;
        }
    }
};
</script>

<style scoped>
.active {
    text-decoration: underline;
}
.fade-enter-active, .fade-leave-active {
    transition: opacity .25s ease-in-out;
}
.fade-enter-from, .fade-leave-to {
    opacity: 0;
}
.fade-enter-to, .fade-leave-from {
    opacity: 1;
}

</style>

