
<template>
    <div class="top-menu">
        <img class="back-button" @click="simulateDeck" src="./play-with-deck-button.png" alt="play">
    </div>
    <van-row class="default-font-style" justify="center" gutter="100">
        <van-col span="8"> 
            <div class="attribute-controls">
                <div>
                    <StepperComponent :initValue="stepperInitValue" :min="stepperMinValue" :max="stepperMaxValue" title="Lore" @increment="addAttribute('lore')" @decrement="removeAttribute('lore')"></StepperComponent>
                    <StepperComponent :initValue="stepperInitValue" :min="stepperMinValue" :max="stepperMaxValue" title="Empathy" @increment="addAttribute('empathy')" @decrement="removeAttribute('empathy')"></StepperComponent>
                    <StepperComponent :initValue="stepperInitValue" :min="stepperMinValue" :max="stepperMaxValue" title="Harmony" @increment="addAttribute('harmony')" @decrement="removeAttribute('harmony')"></StepperComponent>
                    <StepperComponent :initValue="stepperInitValue" :min="stepperMinValue" :max="stepperMaxValue" title="Grace" @increment="addAttribute('grace')" @decrement="removeAttribute('grace')"></StepperComponent>
                    <StepperComponent :initValue="stepperInitValue" :min="stepperMinValue" :max="stepperMaxValue" title="Power" @increment="addAttribute('power')" @decrement="removeAttribute('power')"></StepperComponent>
                </div>
                <h3>Deck must contain 25 attribute cards</h3>
                <h2 :class="{ 'pulse': deckSize > 25, 'pulse-green' : deckSize < 25}">Attribute Total: {{ this.deckSize }}</h2>
                <div>
                    <StepperComponent :initValue="2" :min="1" :max="3" title="Health" @increment="addCard('injury')" @decrement="removeCard('injury')"></StepperComponent>
                </div>
                <div>
                    <StepperComponent :initValue="0" :min="0" :max="4" title="Stitches" @increment="addCard('stitch')" @decrement="removeCard('stitch')"></StepperComponent>
                </div>
                <div>
                    <ThreadComponent :initValue="2" :min="1" :max="3" title="Threads" @add="addCard($event)" @remove="removeCard($event)"></ThreadComponent>
                </div>
                <div>
                    <StepperComponent :initValue="0" :min="0" :max="2" title="Relics" @increment="addCard('relic')" @decrement="removeCard('relic')"></StepperComponent>
                </div>
            </div>
        </van-col>
    </van-row>
</template>

<script>
import StepperComponent from '../BasicInterfaceComponents/StepperComponent.vue';
import ThreadComponent from '../BasicInterfaceComponents/ThreadComponent.vue';
export default {
    name: 'DeckSimComponent',
    components: {
        StepperComponent,
        ThreadComponent
    },
    data() {
        return {
            deck: [],
            stepperInitValue: 5,
            stepperMaxValue: 10,
            stepperMinValue: 1,
            deckSize: 25, // 5 cards per attribute
        };
    },
    methods: {
        addAttribute(attribute) {
            this.deckSize++;
            this.addCard(attribute);
        },
        removeAttribute(attribute) {
            this.deckSize--;
            this.removeCard(attribute);
        },
        addCard(attribute) {
            this.deck.push(attribute);
        },
        removeCard(attribute) {
            const index = this.deck.findIndex(card => card === attribute);
            if (index !== -1) {
                this.deck.splice(index, 1);
            }
        }, 
        simulateDeck() {
            this.$emit('deck', this.deck);
        }
    },
    created() {
        const attributes = ['lore', 'empathy', 'harmony', 'grace', 'power'];
            for (const attribute of attributes) {
                for (let i = 0; i < 5; i++) {
                    this.deck.push(attribute);
                }
            }
            for (let i = 0; i < 2; i++) {
                    this.deck.push('injury');
                }
    }
};
</script>

<style scoped>
.active {
    text-decoration: underline;
}
.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.25s ease-in-out;
}
.fade-enter-from,
.fade-leave-to {
    opacity: 0;
}
.fade-enter-to,
.fade-leave-from {
    opacity: 1;
}

.attribute-controls {
    margin-top: 25px;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
}

.pulse {
    color: #CF5C36;
    animation: pulse 2s infinite;
}

.pulse-green {
    color: #E6B321;
    animation: pulse 2s infinite;
}

.top-menu {
    padding-top: 15px;
}

.back-button {
    width: auto;
    height: 25px;
    transition: transform 0.3s ease;
    color: #FFF3E4;
  }
  
.back-button:hover {
    transform: scale(1.1); /* Enlarge the link by 10% on hover */
    cursor: pointer;
    
}

@keyframes pulse {
    0% { transform: scale(1); }
    50% { transform: scale(1.1); }
    100% { transform: scale(1); }
}

</style>
