<template>
    <van-row ref="topRow">
        <van-col span="24">
            <transition name="fade" mode="out-in">
                <div class="top-card" @click="drawCard">
                    <img :src="require(`./${topCard}-image.png`)" alt="Card Image" v-if="drawPile.length !== 0">
                    <img src="./empty-image.png" alt="Card Image" v-if="drawPile.length === 0">
                </div>
            </transition>
        </van-col>
    </van-row>
    <div class="break"></div>
    <van-row justify="center" gutter="20">
        <van-col span="20">
            <div class="deck-controls">
                <img class="deck-controls-item" @click="undoDraw" src="./undo.png" alt="Undo">
                <h3 v-if="testedTotal === 0" class="default-font-style complication">&#x02190;Undo/New Test&#x02192;</h3>
                <h2 v-if="testedTotal === 1" class="default-font-style complication">Favoured Outcome({{ testedTotal }})</h2>
                <h2 v-if="testedTotal > 1 && testedTotal <= 3" class="default-font-style complication">Minor Complication({{ testedTotal }})</h2>
                <h2 v-if="testedTotal > 3" class="default-font-style complication">Major Complication({{ testedTotal }})</h2>
                <img class="deck-controls-item" @click="resetTest" src="./new-test.png" alt="New Test">
            </div>
        </van-col>
    </van-row>
    <div class="break"></div>
    <van-row justify="center">
        <van-col span="20">
            <div class="card-button-controls">
                <img class="deck-controls-item" :class="{ 'disabled': injuryPile.length === 0 }" @click="addInjury" src="./injury-button.png" alt="Injury">
                <img class="deck-controls-item" :class="{ 'disabled': injuryPile.length === maxInjury }" @click="healInjury" src="./heal-button.png" alt="heal">
                <img class="deck-controls-item" :class="{ 'disabled': relicPile.length === 0 }" @click="useRelic" src="./relic-button.png" alt="relic">
                <img class="deck-controls-item" :class="{ 'disabled': omenPile.length === 0 }" @click="useOmen" src="./omen-button.png" alt="omen">
                <img class="deck-controls-item" @click="shuffleDeck" src="./shuffle-button.png" alt="New Test">
            </div>
        </van-col>
    </van-row>
    <van-row justify="center" gutter="20">
        <van-col span="12">
            <h2 class="default-font-style" style="float: right;">Draw Pile:{{ drawPile.length }}</h2>
        </van-col>
        <van-col span="12">
            <h2 class="default-font-style" style="float: left;">Discard Pile:{{ discardPile.length }}</h2>
        </van-col>
    </van-row>

</template>

<script>

export default {
    name: 'DeckPlayComponent',
    props: {
        deck: {
            type: Array,
            required: true,
        },
    },
    data() {
        return {
            drawPile: this.deck,
            discardPile: [],
            injuryPile: [],
            maxInjury: 2,
            omenPile: ['omen'],  
            relicPile: [],  
            stichPile: [],
            topCard: 'back',
            testedTotal: 0,
        };
    },
    mounted() {
        this.moveRelicsAndInjuries();
        this.shuffleDeck();

        window.scrollTo(0, 350);
    },
    methods: {
        moveRelicsAndInjuries() {
            let injuryCount = 0;
            for (let i = this.drawPile.length - 1; i >= 0; i--) {
                const card = this.drawPile[i];
                if (card === 'relic') {
                    this.relicPile.push(card);
                    this.drawPile.splice(i, 1);
                } else if (card === 'injury') {
                    this.injuryPile.push(card);
                    this.drawPile.splice(i, 1);
                    injuryCount++;
                }
            }
            this.maxInjury = injuryCount;
        },
        undoDraw() {
                if (this.testedTotal === 0) {
                    return;
                }
                const card = this.discardPile.pop();
                this.drawPile.push(card);

                if(this.discardPile.length === 0) {
                    this.topCard = 'back';
                }
                else {
                    this.topCard = this.discardPile[this.discardPile.length - 1];
                }

                this.testedTotal--;
            },
        resetTest() {
            this.testedTotal = 0;
            this.topCard = 'back';
        },  
        drawCard() {
            if (this.drawPile.length === 0) {
                return;
            }
            const card = this.drawPile.pop();

            //omens and relics dont count towards the total test value, they just get removed right away. 
            if(card === 'omen'){
                this.omenPile.push(card);
                alert('You have drawn one of the lore keepers omens');
            }
            else if (card === 'relic') {
                this.relicPile.push(card);
                alert('Your relic is available');
            }
            else {
                this.discardPile.push(card);
                this.topCard = card;
                this.testedTotal++;
            }

        },
        shuffleDeck(isFullDeck = true) {

            if(isFullDeck){
                this.resetTest();
                this.drawPile.push(...this.discardPile);
                this.discardPile = [];
            }
            for (let i = this.drawPile.length - 1; i > 0; i--) {
                const j = Math.floor(Math.random() * (i + 1));
                [this.drawPile[i], this.drawPile[j]] = [this.drawPile[j], this.drawPile[i]];
            }
        },
        useRelic() {
            const card = this.relicPile.pop();
            this.drawPile.push(card);
            this.shuffleDeck(false);
        },
        useOmen() {
            const card = this.omenPile.pop();
            this.drawPile.push(card);
            this.shuffleDeck(false);
        },
        addInjury() {
            if(this.injuryPile.length === 1) {
               alert('you have exausted your injury deck.  You must make a "Return to the weave" move')
            }
            const card = this.injuryPile.pop();
            this.drawPile.push(card);
            this.shuffleDeck(false);
        },
        healInjury() {

            let healed = false;

            //Check discard pile first
            let index = this.discardPile.findIndex(card => card === 'injury');
            if (index !== -1) {
                this.discardPile.splice(index, 1);
                healed = true;
            }

            //Check draw pile if no injury in discard pile
            if(!healed){
                index = this.drawPile.findIndex(card => card === 'injury');
                if (index !== -1) {
                    this.drawPile.splice(index, 1);
                    healed = true;
                    this.shuffleDeck(false);
                }    
            }
            
            if(healed){
                this.injuryPile.push('injury');
            }
        },
    },
};
</script>

<style scoped>
.active {
    text-decoration: underline;
}
.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.25s ease-in-out;
}
.fade-enter-from,
.fade-leave-to {
    opacity: 0;
}
.fade-enter-to,
.fade-leave-from {
    opacity: 1;
}
.progress-card {
    height: 120px;
    width: auto;
    margin-right: -8px;
    margin-left: -8px;
}
.top-card {
    position: relative;
    margin-top: 50px;
    user-select: none;
}
.top-card img {
    transition: transform 0.3s ease;
}

.top-card img:hover {
    transform: scale(1.1); /* Enlarge the link by 10% on hover */
    cursor: pointer;
    
}

.deck-controls {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    max-width: 400px;
    min-height: 100px;
    margin-left: auto;
    margin-right: auto;
}


.deck-controls img {
    position: relative;
    user-select: none;
    max-height: 50px;
    max-width: auto;
    margin: 20px;
    transition: transform 0.3s ease;
}
.deck-controls img:hover {
    transform: scale(1.1); /* Enlarge the link by 10% on hover */
    cursor: pointer;
    
}

.card-button-controls {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    max-width: 400px;
    margin-left: auto;
    margin-right: auto;
}


.card-button-controls img {
    position: relative;
    user-select: none;
    max-height: auto;
    max-width: 60px;
    margin: 2px;
    transition: transform 0.3s ease;
}
.card-button-controls img:hover {
    transform: scale(1.1); /* Enlarge the link by 10% on hover */
    cursor: pointer;
    
}

.top-card img {
    max-width: 200px;
    max-height: auto;
    top: 0;
    left: 0;
}
.disabled {
    filter: brightness(50%);
    pointer-events: none;
}

h2.complication {
    text-align: center;
    min-width: 180px;
}

h3.complication {
    text-align: center;
    min-width: 180px;
    font-size: 1em;
}

</style>