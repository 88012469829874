<template>
    <div>
        <van-row justify="center" gutter="20">
            <van-col xs="24" sm="12" md="8" lg="8">
                <div class="main-content">
                    <h2>Power and Empathy</h2>
                    <p>A scarred is so fiercely devoted to their community that they are willing to sacrifice themselves in its defense.  Violence is a burden borne by the soul and they believe they must shoulder it on behalf of those they love.  They are warriors, hunters, and protectors. </p>
                    <div class="break"></div>
                    <div class="image-center">
                        <a :href="scarredPdf" class="download-button">Download Scarred Playbook</a>
                    </div>
                </div>
            </van-col>
            <van-col xs="24" sm="12" md="8" lg="8">
                <div class="main-content">
                    <h2>Moves</h2>
                    <div class="buffered-content">
                        <ul class="features">
                            <li><strong>Violence:</strong> When you want to use violence, test for a thread. Include the thread in any complications that arise. This act of violence always complicates your relationship with the thread, even if it’s the first card drawn.</li>
                            <li class="sub-bullet">What constitutes “Violence” is something that is going to vary from player to player, game to game and community to community. In Wick and Wander it is common for folks to eat fish, game, or livestock. This doesn’t necessarily mean that only the Scarred can go hunting or fishing, though it can if that is true for your community. Ultimately, you and the Lore Keeper will decide when something in the narrative is considered violence.</li>
                        </ul>
                    </div>
                    <h2>Features</h2>
                    <div class="buffered-content">
                        <ul class="features">
                            <li>Start with 3 Injury Cards instead of 2</li>
                            <li>When you draw an injury, take a minor complication instead of a major complication</li>
                            <li>Replace the thread "who do you love?" with "Who would you kill for?"</li>
                        </ul>
                    </div>
                </div>
            </van-col>
        </van-row>
    </div>
</template>

<script>
import scarredPdf from '@pathPdfs/Scarred.pdf'
export default {
    name: 'ScarredComponent',
    data() {
        return {
            scarredPdf: scarredPdf
            // Your data properties here
        };
    }
};
</script>

