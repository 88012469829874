<template>
    <div>
        <van-row justify="center" gutter="20">
            <van-col xs="24" sm="12" md="8" lg="8">
                <div class="main-content">
                    <h2>Power and Harmony</h2>
                    <p>The weave itself has substance and power even in the absence of Daemons. A Catalyst is someone who can sense, and direct, the flow of the weave in crude but powerful ways.</p>
                    <div class="break"></div>
                    <div class="image-center">
                        <a :href="catalystPdf" class="download-button">Download Catalyst Playbook</a>
                    </div>
                </div>
            </van-col>
            <van-col  xs="24" sm="12" md="8" lg="8">
                <div class="main-content">
                    <h2>Moves</h2>
                    <div class="buffered-content">
                        <ul class="features">
                            <li>
                                <strong>Push or Pull:</strong> When you wish to create a wave of force pushing or pulling away from yourself, test with <strong>Power</strong>.
                            </li>
                            <li>
                                <strong>Calm:</strong> When you wish to create a zone of calm, redirecting or restraining the flow of the weave and temporarily limiting a daemon's ability to act on things within the zone, test with <strong>Harmony</strong>.
                            </li>
                            <li>
                                <strong>Control:</strong> When you wish to abruptly and momentarily accelerate or decelerate some nearby physical process, test with <strong>Harmony</strong>.
                            </li>
                        </ul>
                    </div>
                </div>
            </van-col>
        </van-row>
    </div>
</template>

<script>
import catalystPdf from '@pathPdfs/Catalyst.pdf'

export default {
    name: 'CatalystComponent',
    data() {
        return {
            catalystPdf: catalystPdf
        };
    }
};
</script>

<style scoped>
/* Your component-specific styles here */
</style>
