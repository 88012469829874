    <template>
        <div class="main-content">

            <van-row justify="center">
                <van-col class="van-col" span="24">
                    <h2>What is Wick and Wander?</h2>
                    <p>
                        <em>Wick and Wander</em> is an immersive <strong>Tabletop Role-Playing Game</strong> designed for <strong>2-6 players and one Lore Keeper</strong>. Drawing inspiration from various <em>Powered by the Apocalypse</em> games, but featuring a unique card-based resolution system, the game emphasizes exploration and player relationships over combat, creating intense narrative adventures.
                    </p>
                    <p>
                        The world is one of pastoral abundance where we find humanity, having recovered from the climate apocalypse, learning to live in harmony with an enigmatic force known as <strong>the weave</strong>.
                    </p>
                    <p>
                        Human communities huddle around a network of stabilizing artifacts known as <strong>the singing stones</strong>, which protect them from the chaos of the wild world outside.
                    </p>
                    <p>
                        Our players are from one of many such communities dotting the now verdant landscape. While the world outside is dangerous, it is also beautiful, vast, and full of wonder.
                    </p>
                </van-col>
            </van-row>
            <div class="break"></div>
            <van-row id="real-reason" justify="center">
                <van-col class="van-col" span="24">
                    <h2>What is Wick and Wander, really?</h2>
                    <p>Wick and Wander is a game I made about connections.  The setting is cool, I think, and the gameplay is fun, as far as I can tell, but really its about being together.  The game is designed to focus on and celebrate presence, love, connection.  RPGs are about holding space together and WnW is my best attempt at maximizing that.  When I first started working on the game, I started a note on my phone called "remember the feeling" and I added little blurbs from my own life.  Feelings that felt like magic.  I look at this list before I work on the game to bring my mind back to what it is supposed to feel like.  As with so much in life, it falls woefully short of capturing these feelings, but its nice to have a north star and this is mine.</p>
                </van-col>
            </van-row>
            <van-row id="real-reason" justify="center">
                <van-col class="van-col" span="20">
                    <ul class="features">
                        <li>
                            Its the middle of the night. I am sitting here listening to a thunder storm. An hour ago it was right above the house, and each thunder clap felt like a bass drum. The animals were scared and sleeping was impossible. That feeling, of knowing that the outside is harsh and violent, but beautiful in its own way, all while bundled in a little mote of warmth, is the feeling I want this game to capture. That feeling that the world is a stormy ocean and the people around you are all that keeps you from floating off into the darkness.
                        </li>
                        <li>
                            Sometimes you breath in and it feels like the first breath you’ve ever taken. It just rained and you can smell everything green around you breathing in time. That breath that fills your whole body when you and the world are waking up at the same time.
                        </li>
                        <li>
                            I want this game to feel like waking up before everyone else and watching the sun rise.
                        </li>
                        <li>
                            Have you ever laid down in the middle of a field at night and looked up at the stars. That sound of the tall grass moving in waves with the gentle wind. The soft sadness of knowing that no picture or song will ever truly capture it. You slide your socks off and let the breeze tickle your toes.
                        </li>
                        <li>
                            That feeling when someone you love curls up next to you under a sheet on a cool summer day, and you make yourselves into a little ball to keep your feet underneath as the wind from an open window ripples over you. Maybe you hear people talking and laughing just outside. You cant make out what they are saying but they are happy and you feel it swirling together with your own happiness.
                        </li>
                        <li>
                            When you wake up in the middle of the night and your cat, who generally sleeps right next to you isnt in his bed, and the half asleep part of your brain that invents worries starts to think “did he slip out when I stepped outside” and you know he didnt, but the question just sits there crouching on your brain until eventually you get out of bed and sleepily wander around the house with a lantern looking for him. Getting more and more concerned and more and more awake with each room you check, until you decide to go put pants on and check outside, so you head back to your room to get them, and he is asleep in his bed. Was he always there?
                        </li>
                        <li>
                            When you are half asleep and you see what you think is your loved one and you reach over to hold their hand, but it turns out its just a pile of blankets. You get sad and a little worried but then you hear them coming up the stairs. They were just going to get a drink of water. They are back now and you lay your hand on their back and feel them breathing.
                        </li>
                        <li>
                            That feeling of encountering something you dont completely understand. A combination of wonder and fear and excitement all mixing together in an intoxicating way.
                        </li>
                        <li>
                            The feeling of waking up to the sun on your face and the wind rustling your hair.
                        </li>
                        <li>
                            That feeling of hearing a waterfall in the distance well before you can see it. Then as you approach, the air gets damp and you start to see rainbows.
                        </li>
                        <li>
                            That feeling of seeing a friend for the first time in years and you are transported back to when you were younger. Joy and sadness swirling together.
                        </li>
                        <li>
                            The feeling of doing something that someone you love taught you. Tying a knot, or baking bread, and for that moment, the person is with you even if they are no longer alive.
                        </li>
                        <li>
                            That feeling of being so tired you feel like you are going to collapse, but when you finally catch your breath, all you can do is laugh.
                        </li>
                        <li>
                            That feeling of working quietly towards a goal for months or years and not telling anyone, until finally you accomplish it and all you can do is weep with relief. Then you realize the goal is done and you weep with sadness.
                        </li>
                        <li>
                            That feeling of trusting the people around you despite all the differences between you. Knowing that in this moment they want what you want, they want everyone to thrive.
                        </li>
                    </ul>
                </van-col>
            </van-row>
            <div class="break"></div>
            <van-row justify="center">
                <van-col class="van-col" span="24">
                    <h2>Touchstones</h2>

                    <p class="features">
                        <strong>Movies and TV Shows: </strong>Princess Mononoke, My Neighbor Totoro, Spirited Away, Scavengers Reign
                    </p>
                    
                    <p class="features">
                        <strong>Books: </strong>Dinotopia, A Psalm to the Wild Built, Always Coming Home, Annihilation, Station 11, LOTR/The Hobbit
                    </p>

                    <p class="features">
                        <strong>Comics: </strong>Manifest Destiny, East of West
                    </p>

                    <p class="features">
                        <strong>Video Games: </strong>Jusant
                    </p>
                    <p class="features">
                        <strong>Smells:</strong> Tilled earth, fresh herbs, moss, a wet stone at the bottom of a waterfall, the hair of someone you love after a winter walk, the curl of smoke from a snuffed match
                    </p>

                    <p class="features">
                        <strong>Sounds:</strong> Rustling leaves and grass, The sound of a large branch breaking in the distance, Thunderous rain against a window while a fire crackles, The squeaky crunch as you step through soft knee deep snow
                    </p>

                    <p class="features">
                        <strong>Sensations:</strong> Damp pant legs as you walk through a dew covered field in the morning, breathing deep on a cold day and feeling the frost fill your nose, Splashing your face with ice cold water from a mountain stream, Wrapping a heavy woolen blanket around yourself but leaving your foot out to catch heat from the fire
                    </p>

                    <p class="features">
                        <strong>Tastes:</strong> Japanese green tea, A granola bar after a day long hike, a fresh cut slice of radish, a handful of bright blue berries right from the bush
                    </p>
                </van-col>
            </van-row>
        </div>      
    </template>

    <script>
    export default {
        name: 'GameComponent',
    }
    </script>
    <style>
    .real-a {
        display: inline-block;
        color: #FFF3E4;
        text-align: center;
        padding: 14px 16px;
        text-decoration: none;
        transition: transform 0.3s ease;
    }

    .real-a:hover {
        transform: scale(1.1); /* Enlarge the link by 10% on hover */
    }
    </style>

