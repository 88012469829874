<template>
    <nav class="menu-bar">
        <van-row justify="center">
            <van-col span="10">
                <img class="responsive-image" :src="wnwLogo" alt="Logo">
            </van-col>
        </van-row>
        <van-row justify="center">
            <van-col span="24">
                <ul>
                    <li :class="{ 'active': $route.path === '/' }"><router-link to="/">Home</router-link></li>
                    <li :class="{ 'active': $route.path === '/pathFiles' }"><router-link to="/pathFiles">Playbooks</router-link></li>
                    <li :class="{ 'active': $route.path === '/historyOptions' }"><router-link to="/historyOptions">Histories</router-link></li>
                    <li :class="{ 'active': $route.path === '/coreRules' }"><router-link to="/coreRules">Core Rules</router-link></li>
                    <li :class="{ 'active': $route.path === '/deckSim' }"><router-link to="/deckSim">Deck Sim</router-link></li>
                    <li>
                        <a href="https://discord.gg/rJjxMRt2G3" class="discord-link">
                            <img :src="discordLogo" alt="Join us on Discord" class="discord-logo">
                        </a>
                    </li>
                </ul>
            </van-col>
        </van-row>
        
    </nav>
</template>

<script>
import wnwLogo from '@logos/wnw-logo.png'
import discordLogo from '@logos/discordLogo.png'

export default {
    name: 'NavigationBar',
    data() {
        return {
            wnwLogo,
            discordLogo
        }
    },
    emits: ['update:route'],
}
</script>

<style scoped>
header {
    margin-bottom: 10px;
}

.responsive-image {
    display: block;
    max-width: 150px; /* Set this to the maximum size you want */
    width: 100%;
    height: auto;
    margin-left: auto;
    margin-right: auto;
}

.tight-list {
    padding: 0;
    margin-top: -30px;
}

.active a{
    text-decoration: underline;
}

</style>
