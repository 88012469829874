<template>
    <div>
        <van-row justify="center" gutter="20">
            <van-col xs="24" sm="12" md="8" lg="8">
                <div class="main-content">
                    <h2>Lore and Harmony</h2>
                    <p>The world of Wick and Wander is abundant.  Medicinal plants can match or exceed the efficacy of pre-collapse medicine, but they require a skilled practitioner to prepare them correctly.  Shifting characteristics from plants to animals, or capturing those characteristics in tinctures is not something to be done lightly</p>
                    <div class="break"></div>
                    <div class="image-center">
                        <a :href="naturalistPdf" class="download-button">Download Naturalist Playbook</a>
                    </div>
                </div>
            </van-col>
            <van-col xs="24" sm="12" md="8" lg="8">
                <div class="main-content">
                    <div>
                        <h2>Moves</h2>
                        <div class="buffered-content">
                            <ul class="features">
                                <li><strong>Graft:</strong> When you want to transfer some trait from one plant or animal to another, test with <strong>Harmony</strong></li>
                                <li class="sub-bullet">Stitching humans is forbidden in almost all enclaves throughout the Horn.</li>
                                <li class="sub-bullet">You must have the plants or animals you are stitching close at hand.</li>
                                <li><strong>Distill:</strong> When you wish to craft a tincture that transfers a trait from a plant to a person temporarily, test with <strong>Lore</strong></li>
                            </ul>
                        </div>
                        <h2>Features</h2>
                        <div class="buffered-content">
                            <ul class="features">
                                <li>When using the <strong>Grow</strong> move to consume a bodum, you may ask for specific memories about specific things rather than simply getting a memory at random.</li>
                            </ul>
                            
                        </div>
                    </div>
                </div>
            </van-col>
        </van-row>
    </div>
</template>

<script>
import naturalistPdf from '@pathPdfs/Naturalist.pdf'
export default {
    name: 'NaturalistComponent',
    data() {
        return {
            naturalistPdf: naturalistPdf
            // Your data properties here
        };
    }
};
</script>

